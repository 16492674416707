import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios'; // Import axios for making HTTP requests
import './style.scss';

import blog1 from '../../images/blog-page/1.jpg';

const BlogPost = () => {
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const BACKEND_HOST = process.env.REACT_APP_BACKEND_HOST;

                const response = await axios.get(`${BACKEND_HOST}/blogs`);
                setBlogs(response.data.data);
            } catch (error) {
                console.error('Error fetching blogs:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchBlogs();
    }, []);

    const truncateText = (text, maxLength = 100) => {
        if (text.length > maxLength) {
            return `${text.slice(0, maxLength)}...`;
        }
        return text;
    };

    return (
        <div className="blogPostContainer">
            {loading ? (
                <p>Loading blogs...</p>
            ) : blogs.length > 0 ? (
                <div className="blogGrid">
                    {blogs.map((blog, i) => (
                        <div key={i} className="blogCard">
                            <div className="blogImage">
                                <img src={blog.banner_url || blog1} alt="Blog" />
                            </div>
                            <div className="blogContent">
                                <h3>
                                    <Link to={`/blog/${blog.blog_id}`}>{blog.title || 'Blog Title'}</Link>
                                </h3>
                                <p dangerouslySetInnerHTML={{ __html: truncateText(blog.content || 'Blog content...', 120) }}></p>
                                <div className="blogMeta">
                                    <span>{blog.creator_name || 'By Aliza Anne'}</span> |{' '}
                                    <span>{blog.creation_date || 'Oct 12, 2018'}</span>
                                </div>
                                <Link className="readMore" to={`/blog/${blog.blog_id}`}>
                                    Read more
                                </Link>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <p>No blogs available</p>
            )}
        </div>
    );
};

export default BlogPost;
