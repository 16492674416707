import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function BlogCard(props) {
  const [expanded, setExpanded] = React.useState(false);
  const { data, deleteBlog,prefillDialog } = props;
  const trimStringToLength = (str, maxLength) => {
    return str.length > maxLength ? str.substring(0, maxLength) : str;
  };

  const isVideoFile = (url) => {
    const videoExtensions = ['.mp4', '.avi', '.mov', '.mkv', '.webm'];
    return videoExtensions.some((ext) => url.toLowerCase().endsWith(ext));
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
            A
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={data.title}
        subheader={data.creation_date}
      />
      {isVideoFile(data.banner_url) ? (
        // Render video player for video files
        <CardMedia
          component="video"
          height="194"
          controls
          src={data.banner_url}
          alt="video"
        />
      ) : (
        // Render image for other file types
        <CardMedia
          component="img"
          height="194"
          image={data.banner_url}
          alt="image"
        />
      )}
      <CardContent>
        <Typography variant="body2" color="text.secondary"  dangerouslySetInnerHTML={{ __html:trimStringToLength(data.content, 100) }} >
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label="edit" onClick={()=>prefillDialog(data)}>
          <EditIcon />
        </IconButton>
        <IconButton aria-label="delete" onClick={() => deleteBlog(data)}>
          <DeleteIcon />
        </IconButton>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>{data.content}</Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}
