import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Joi from 'joi-browser';
import { toast } from 'react-toastify';
import "./style.scss";

import HeaderBotton from "../../components/HeaderBottom";
import HeaderTop from "../../components/HeaderTop";
import Breadcumb from "../../components/Breadcumb";
import NewsLetter from "../../components/Newsletter";
import FooterArea from "../../components/FooterArea";

// images
import breadcumb from "../../images/breadcumb/1.jpg";

const breadcumbMenu = [
  { name: "Home", route: "/" },
  { name: "Blog" },
];

const BlogPage = () => {
  const { blogId } = useParams();
  const [blogData, setBlogData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    country: '',
    message: ''
  });
  const [errors, setErrors] = useState({});

  // Joi validation schema
  const schema = {
    email: Joi.string().email({ minDomainAtoms: 2 }).required().error(errors => {
      errors.forEach(err => {
        switch (err.type) {
          case "string.email":
            err.message = 'Email must be a valid email';
            break;
          default:
            err.message = 'Email cannot be empty';
            break;
        }
      });
      return errors;
    }),
    phone: Joi.string().min(10).max(13).required().error(errors => {
      errors.forEach(err => {
        switch (err.type) {
          case "string.min":
            err.message = 'Phone number must be at least 10 characters long';
            break;
          case "string.max":
            err.message = 'Phone number cannot be longer than 13 characters';
            break;
          default:
            err.message = 'Phone cannot be empty';
            break;
        }
      });
      return errors;
    }),
    name: Joi.string().required().error(errors => {
      errors.forEach(err => {
        err.message = 'Name cannot be empty';
      });
      return errors;
    }),
    country: Joi.string().allow('').optional(),
    message: Joi.string().min(10).required().error(errors => {
      errors.forEach(err => {
        switch (err.type) {
          case "string.min":
            err.message = 'Message must be at least 10 characters long';
            break;
          default:
            err.message = 'Message cannot be empty';
            break;
        }
      });
      return errors;
    }),
  };

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/blogs/${blogId}`);
        const data = await response.json();
        setBlogData(data);
      } catch (error) {
        console.error("Error fetching blog:", error);
        toast.error('Failed to load blog details');
      } finally {
        setLoading(false);
      }
    };

    fetchBlog();
  }, [blogId]);

  // Validate individual input
  const validateProperty = (name, value) => {
    const obj = { [name]: value };
    const propertySchema = { [name]: schema[name] };
    const { error } = Joi.validate(obj, propertySchema);
    return error ? error.details[0].message : null;
  };

  // Handle input changes with validation
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const errorMessage = validateProperty(name, value);
    
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));

    // Update errors
    setErrors(prevErrors => {
      const newErrors = { ...prevErrors };
      if (errorMessage) {
        newErrors[name] = errorMessage;
      } else {
        delete newErrors[name];
      }
      return newErrors;
    });
  };

  // Validate entire form
  const validateForm = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(formData, schema, options);
    
    if (!error) return null;

    const formErrors = {};
    for (let item of error.details) {
      formErrors[item.path[0]] = item.message;
    }
    return formErrors;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate the entire form
    const formErrors = validateForm();
    
    // If there are validation errors, set them and stop submission
    if (formErrors) {
      setErrors(formErrors);
      return;
    }

    const BACKEND_HOST = process.env.REACT_APP_BACKEND_HOST;

    try {
      const response = await fetch(`${BACKEND_HOST}/contact`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        
        body: JSON.stringify({
          name: formData.name,
          phone: formData.phone,
          email: formData.email,
          message: formData.message,
          country: formData.country,
        }),
      });

      if (response.ok) {
        // Reset form after successful submission
        setFormData({
          name: '',
          phone: '',
          email: '',
          country: '',
          message: ''
        });
        setErrors({}); // Clear any existing errors
        
        toast.success('Query submitted successfully!');
      } else {
        toast.error('Failed to submit query.');
      }
    } catch (error) {
      console.error('Submission error:', error);
      toast.error('An error occurred while submitting the query.');
    }
  };

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (!blogData) {
    return <div className="error">Blog not found</div>;
  }

  return (
    <>
      <header className="headerArea">
        <HeaderTop />
        <HeaderBotton />
      </header>
      <Breadcumb
        className="breadcumbArea"
        title="Blog Details"
        breadcumbMenu={breadcumbMenu}
        background={breadcumb}
      />
      <div className="blogPage">
        <div className="container">
          <div className="contentWrapper">
            <div className="blogContent">
              <h1 className="blogTitle">{blogData.title}</h1>
              <div className="blogBreadcrumb">{blogData.breadcrumb}</div>
              <img
                className="blogImage"
                src={blogData.banner_url}
                alt={blogData.title}
              />
              <div
                className="blogDescription"
                dangerouslySetInnerHTML={{ __html: blogData.content }}
              />
            </div>
            <div className="queryForm">
              <h2>Contact Us</h2>
              <p>Fill out this form and our team will get back to you shortly!</p>
              <form onSubmit={handleSubmit}>
                <div className="formInput">
                  <input 
                    type="text" 
                    name="name" 
                    placeholder="Enter Your Full Name *" 
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                  {errors.name && <p className="error-message">{errors.name}</p>}
                </div>
                <div className="formInput">
                  <input 
                    type="tel" 
                    name="phone" 
                    placeholder="Enter Your Mobile Number *" 
                    value={formData.phone}
                    onChange={handleInputChange}
                  />
                  {errors.phone && <p className="error-message">{errors.phone}</p>}
                </div>
                <div className="formInput">
                  <input 
                    type="email" 
                    name="email" 
                    placeholder="Enter Your Email *" 
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                  {errors.email && <p className="error-message">{errors.email}</p>}
                </div>
                <div className="formInput">
                  <input 
                    type="text" 
                    name="country" 
                    placeholder="Country *" 
                    value={formData.country}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="formInput">
                  <textarea 
                    name="message" 
                    placeholder="Enter Your Legal Issue *"
                    value={formData.message}
                    onChange={handleInputChange}
                  ></textarea>
                  {errors.message && <p className="error-message">{errors.message}</p>}
                </div>
                <button type="submit">Submit Query</button>
              </form>
            </div>
          </div>
        </div>
      </div>
     
      <FooterArea />
    </>
  );
};

export default BlogPage;