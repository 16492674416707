import React, { useState, useEffect } from 'react';
import SectionTitle from '../Title';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './style.scss';

const BlogArea = ({ className, title, subTitle }) => {
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const BACKEND_HOST = process.env.REACT_APP_BACKEND_HOST;
                const response = await axios.get(`${BACKEND_HOST}/blogs`);
                setBlogs(response.data.data);
            } catch (error) {
                console.error('Error fetching blogs:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchBlogs();
    }, []);

    const formatDate = (dateString) => {
        try {
            const date = new Date(dateString);
            return new Intl.DateTimeFormat('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            }).format(date);
        } catch {
            return 'Date unavailable';
        }
    };

    const getTextFromHTML = (html) => {
        if (!html) return '';
        const temp = document.createElement('div');
        temp.innerHTML = html;
        const text = temp.textContent || temp.innerText || '';
        return text.length > 200 ? `${text.slice(0, 200)}...` : text;
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-[400px]">
                <div className="animate-pulse space-y-4">
                    <div className="h-4 w-48 bg-gray-200 rounded"></div>
                    <div className="h-4 w-64 bg-gray-200 rounded"></div>
                </div>
            </div>
        );
    }

    return (
        <div className={`blog-section ${className}`}>
            <div className="container">
                <div className="row">
                    {title && (
                        <div className="col-12" style={{ marginTop: "100px" }}>
                            <SectionTitle
                                title={title}
                                subTitle={subTitle}
                            />
                        </div>
                    )}
                    
                    {blogs.length > 0 ? (
                        <div className="row g-4">
                            {blogs.map((blog, i) => (
                                <div key={blog.blog_id || i} className="col-lg-4 col-md-6 col-12">
                                    <div className="blogWrap">
                                        <div className="blogImage">
                                            <img 
                                                src={blog.banner_url} 
                                                alt={blog.title}
                                                onError={(e) => {
                                                    e.target.onerror = null;
                                                    e.target.src = '/api/placeholder/400/300';
                                                }}
                                            />
                                        </div>
                                        <div className="blogContent">
                                            <div className="firmName">
                                                Legal Corridors
                                            </div>
                                            <h3>
                                                <Link to={`/blog/${blog.blog_id}`}>
                                                    {blog.title || 'Untitled Post'}
                                                </Link>
                                            </h3>
                                            <div className="blogDescription">
                                                {getTextFromHTML(blog.content)}
                                            </div>
                                            <div className="blogMeta">
                                                <span>Adv. Aman Verma</span>
                                                <span>{formatDate(blog.creation_date)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="col-12 text-center py-12">
                            <p className="text-gray-500 text-lg">No articles available at the moment.</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default BlogArea;